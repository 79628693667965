import { AnimatePresence } from "framer-motion"
import React, { useEffect, useState } from "react"
import "../css/style.scss"
import Footer from "./Footer"
import Header from "./Header"

const Layout = ({ children }) => {
  const [isMobile, setIsMobile] = useState(
    () => typeof window !== "undefined" && window.innerWidth
  )

  useEffect(() => {
    function updateSize() {
      setIsMobile(window.innerWidth)
    }
    window.addEventListener("resize", updateSize)
    updateSize()
  }, [])

  const checkMobile = isMobile < 1023

  return (
    <AnimatePresence exitBeforeEnter initial={checkMobile ? false : true}>
      <div className="site">
        <Header />
        <main className="site-content">{children}</main>
        <Footer />
      </div>

      {/*       <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
        {` `}
        And <a href="https://wordpress.org/">WordPress</a>
      </footer> */}
    </AnimatePresence>
  )
}

export default Layout
