import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import Marquee from "react-fast-marquee"
import "./style.scss"
import Newsletter from "../Newsletter"

const Header = () => {
  const path = useLocation().pathname

  const [opened, setOpen] = useState(false)

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    function updateSize() {
      setIsMobile(window.innerWidth)
    }
    window.addEventListener("resize", updateSize)
    updateSize()
  }, [])

  const checkMobile = isMobile < 768
  return (
    <div className="overlay-nav">
      <div className="header-container">
        <Link to={path.split("/")[1] === "en" ? "/en" : "/"}>
          <h1>PAPAGROOVE</h1>
        </Link>
        {(checkMobile && path === "/en/") ||
        (checkMobile && path === "/en") ||
        (checkMobile && path === "/") ? (
          <div className="marquee-infolettre">
            <Marquee
              gradient={false}
              speed={checkMobile ? 1 : 40}
              direction={checkMobile ? "right" : "left"}
            >
              <Newsletter />
            </Marquee>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Header
