import React from "react"
import "./style.scss"
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const query = useStaticQuery(graphql`
    {
      allSanitySocials(filter: {}, sort: { fields: order, order: ASC }) {
        edges {
          node {
            title
            url
            order
          }
        }
      }
    }
  `)

  const data = query.allSanitySocials.edges

  return (
    <ul className="footer-container">
      {data &&
        data.map((m, i) => (
          <li key={i}>
            <a href={m.node.url} target="_blank" rel="noopener noreferrer">
              {m.node.title}
            </a>
          </li>
        ))}
    </ul>
  )
}

export default Footer
