import addToMailchimp from "gatsby-plugin-mailchimp"

import React, { useState } from "react"
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import "./Newsletter.scss"
import { useLocation } from "@reach/router"

const Newsletter = () => {
  const [emailValue, setEmailValue] = useState("")
  const [confirmed, setConfirmed] = useState("")
  const [error, setError] = useState("")

  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  const path = useLocation().pathname

  // 2. via `async/await`
  const handleSubmit = async e => {
    console.log("yeah")
    e.preventDefault()
    try {
      const result = await addToMailchimp(emailValue)
      setConfirmed("merci")
      setTimeout(() => {
        setOpen(false)
        setConfirmed("")
        setEmailValue("")
      }, 1000)
    } catch (error) {
      setError(setError)
    }
  }

  return (
    <>
      <a
        href="https://mailchi.mp/42aa6131ba45/papagroove"
        target="_blank"
        /*        onClick={onOpenModal} */
        className="button-newsletter"
        style={{ textTransform: "lowercase" }}
      >
        {path.split("/")[1] === "en"
          ? "☆ SUBSCRIBE to our newsletter"
          : "☆ ABONNEZ-VOUS à notre infolettre"}
      </a>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
      >
        <form>
          {path.split("/")[1] === "en"
            ? "Get the latest news from Papagroove:"
            : "Recevez les dernières nouvelles de Papagroove:"}

          <input
            type="email"
            placeholder="email"
            value={emailValue}
            onChange={e => setEmailValue(e.target.value)}
          ></input>
          <button
            type="submit"
            value="Submit"
            className="submit"
            onClick={handleSubmit}
          >
            {path.split("/")[1] === "en" ? "subscribe" : "abonnez-vous"}
          </button>
          {confirmed && (
            <div
              style={{
                color: "green",
                background: "lightgreen",
                padding: ".3rem",
              }}
            >
              {path.split("/")[1] === "en" ? "submited!" : "envoyé!"}
            </div>
          )}
        </form>
      </Modal>
    </>
  )
}

export default Newsletter
